<template>
  <v-app-bar :color="theme.global.name.value == 'light' ? 'white' : ''" elevation="0" density="default"
    class="custom-toolbar" v-model="$store.state.appBarFlag">
    <template v-slot:prepend>
      <v-btn @click="$store.state.navigationFlag = !$store.state.navigationFlag" icon="mdi-dots-vertical"></v-btn>
    </template>
    <v-app-bar-title>
      <form class="d-none d-md-inline-block" @submit.prevent="onSubmitSearch">
        <div class="input-group input-group-sm" :class="searchBarClass">
          <input type="text" class="form-control form-control-alt" :placeholder="$t('search')"
            id="page-header-search-input2" name="page-header-search-input2" v-model="baseSearchTerm" />
          <span class="input-group-text border-0">
            <i class="fa fa-fw fa-search"></i>
          </span>
        </div>
      </form>
      <!-- <v-responsive
            max-width="344"
          >
            <v-text-field variant="solo" :bg-color="theme.global.name.value == 'light' ? '#E0E0E0':''" density="compact" flat class="mt-5" append-inner-icon="mdi-magnify" :label="$t('search')" elevation="0" ></v-text-field>
          </v-responsive> -->
    </v-app-bar-title>
    <template v-slot:append>
      <v-menu :close-on-content-click="false" location="bottom">
        <template v-slot:activator="{ props }">
          <v-btn class="me-2 text-none" :color="theme.global.name.value == 'light' ? '#E0E0E0' : ''" variant="flat"
            v-bind="props">
            <template v-if="$store.state.userImageUrl">
              <img :src="$store.state.userImageUrl" height="20" alt="User Image" class="user-image mr-2" />
            </template>
            <template v-else>
              <v-icon class="mr-2">mdi-account-circle-outline</v-icon>
            </template>
            {{ $store.state.userDetails.name }} <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </template>

        <v-card min-width="300">
          <v-list>
            <v-list-item to="/user_option" :title="$store.state.userDetails.companyName"
              :subtitle="$store.state.userDetails.email">
            </v-list-item>
          </v-list>

          <v-divider></v-divider>

          <v-list>
            <v-list-item>
              <v-btn color="orange-darken-4" block @click="logout()">{{ $t('logout') }}</v-btn>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
      <div class="dropdown mr-2" ref="dropdown">
        <button class="btn btn-primary text-white dropdown-toggle" type="button"
          :style="'background-color:' + buttonColor" :color="buttonColor" id="DropdownMenuButton" aria-haspopup="true"
          aria-expanded="false" data-toggle="dropdown" @click="toggleLanguageDropdown">
          {{ t('language') }}
        </button>
        <div class="dropdown-menu" :class="{ show: showLanguageDropdown }" aria-labelledby="DropdownMenuButton">
          <a class="dropdown-item d-flex align-items-center" v-for="(language, i) in lang" :key="i"
            @click="updateLang(language)">
            <img :src="language.icon" width="22" class="me-2 icon" alt="icon" />
            {{ language.name }}
          </a>
        </div>
      </div>
      <v-menu :close-on-content-click="false" location="bottom">
        <template v-slot:activator="{ props }">
          <v-btn class="text-none me-2" :color="theme.global.name.value == 'light' ? '#E0E0E0' : ''"
            prepend-icon="mdi-bell" variant="flat" v-bind="props">
            <v-badge v-if="totalNewNotifications > 0" color="red" class="ml-3 mb-3" :content="totalNewNotifications">
            </v-badge>
          </v-btn>
        </template>
        <v-card min-width="300">
          <v-card-title style="background-color:rgb(237, 248, 253);" class="text-center">{{ $t('notification')
            }}</v-card-title>
          <v-divider></v-divider>
          <v-list :style="theme.global.name.value == 'light' ? 'background-color:bisque' : 'background-color:#757575'"
            v-if="newNotifications.length > 0">
            <v-list-item v-for="(notification, index) in newNotifications" :key="index" class="mt-2"
              :subtitle="getDiffence(notification.created_at)">
              <template v-slot:title>
                <p class="text-bold">{{ notification.data.message }}</p>
              </template>
              <template v-slot:prepend>
                <v-icon v-if="notification.data.type == 'Success'" style="color: green;">mdi-check-circle</v-icon>
                <v-icon v-if="notification.data.type == 'Error'" style="color: red;">mdi-close-circle</v-icon>
              </template>
              <template v-slot:append>
                <v-tooltip location="end">
                  <template v-slot:activator="{ props }">
                    <v-btn variant="plain" @click="readtNotfication(notification.id, index)">
                      <v-icon v-bind="props">mdi-close-circle</v-icon>
                    </v-btn>
                  </template>
                  {{ $t('markAsRead') }}
                </v-tooltip>
              </template>
            </v-list-item>
          </v-list>
          <v-list v-if="oldNotifications.length > 0">
            <v-list-item v-for="(notification, index) in oldNotifications" :key="index" class="mt-2"
              :subtitle="getDiffence(notification.created_at)">
              <template v-slot:title>
                <p class="text-bold">{{ notification.data.message }}</p>
              </template>
              <template v-slot:prepend>
                <v-icon v-if="notification.data.type == 'Success'" style="color: green;">mdi-check-circle</v-icon>
                <v-icon v-if="notification.data.type == 'Error'" style="color: red;">mdi-close-circle</v-icon>
              </template>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-card-actions v-if="allNewNotifications.length > 0 || allOldNotifications.length > 0">
            <v-btn @click="loadMoreNotification()" block="">{{ $t('loadMore') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
      <!-- <v-btn
              class="text-none me-2"
              :color="theme.global.name.value == 'light' ? '#E0E0E0':''"
              variant="flat"
            >
              <v-icon>mdi-format-list-bulleted</v-icon>
            </v-btn> -->
    </template>
  </v-app-bar>
</template>
<script>
import { ref, toRefs, reactive, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { APIService } from '../Utils/APIService'
const apiService = new APIService()
import Theme from '../Utils/Theme.js'
import { useTheme } from 'vuetify/lib/framework.mjs'
export default {
  setup(props) {
    const fav = ref(true)
    const menu = ref(false)
    const message = ref(false)
    const hints = ref(true)
    const theme = useTheme()
    const store = useStore();
    const router = useRouter();
    const { t, locale } = useI18n();
    const data = reactive({
      newNotifications: [],
      oldNotifications: [],
      allNewNotifications: [],
      allOldNotifications: [],
      totalNewNotifications: 0,
      themeColorOrange: Theme.themeColorOrange,
      buttonColor: Theme.buttonColor,
      errors: [],
      userForm: false,
      countries: [],
      country: null,
      language: null,
      languages: [],
      timeZones: [],
      timeZone: null,
      rows: [10, 20, 30, 40, 50],
      row: null,
      formLoading: false,
      lang: [
        { name: "English", code: "en", icon: "https://dms.api.portal.fw-gruppe.com/storage/1367/uk.png" },
        { name: "Deutsch", code: "de", icon: "https://dms.api.portal.fw-gruppe.com/storage/1365/germany.png" },
        { name: "Polska", code: "pl", icon: "https://dms.api.portal.fw-gruppe.com/storage/1366/poland.png" },
        { name: "Francais", code: "fr", icon: "/img/flags/4x3/fr.svg" },
        { name: "Turkey", code: "tr", icon: "/img/flags/4x3/tr.svg" },
        { name: "Italiano", code: "it", icon: "/img/flags/4x3/it.svg" },
        // { name:"Dansk", code:"dk", icon: "/img/flags/4x3/dk.svg"},
        { name: "Espanol", code: "es", icon: "/img/flags/4x3/es.svg" },
        { name: "Dutch", code: "nl", icon: "/img/flags/4x3/nl.svg" },
        { name: "Swedish", code: "sv", icon: "/img/flags/4x3/se.svg" },
        { name: "Hindi", code: "hi", icon: "/img/flags/4x3/in.svg" },
        { name: "Chinese", code: "cn", icon: "/img/flags/4x3/cn.svg" },
        { name: "Dansk", code: "dk", icon: "/img/flags/4x3/dk.svg" },
      ],
      showLanguageDropdown: false,
    })
    const toggleLanguageDropdown = () => {
      data.showLanguageDropdown = !data.showLanguageDropdown;
      if (data.showLanguageDropdown) {
        document.addEventListener('click', closeDropdownOnClickOutside);
      } else {
        document.removeEventListener('click', closeDropdownOnClickOutside);
      }
    }
    const closeDropdownOnClickOutside = (event) => {
      if (!data.showLanguageDropdown) return;
      const dropdown = document.querySelector('.dropdown');
      if (!dropdown.contains(event.target)) {
        data.showLanguageDropdown = false;
        document.removeEventListener('click', closeDropdownOnClickOutside);
      }
    }
    const updateLang = (language) => {
      locale.value = language.code;
      console.log(language.code);

      const formJson = {}
      formJson.country = store.state.country
      formJson.language_id = null
      formJson.language_iso = language.code
      formJson.row = store.state.rows
      formJson.timezone = store.state.timeZone
      formJson.date_format = store.state.dateFormat
      apiService.saveUserFormInfo(formJson).then((response) => {
        if (response.status === 200) {
          localStorage.setItem('language', language.code);
          console.log("Language Updated successfully");
        }
      })
        .catch(e => {
          console.log(e)
          data.errors.push(e)
        })
    }

    const openUserForm = () => {
      data.userForm = true
      data.formLoading = true
      getUserFormInfo()
    }
    const logout = () => {
      store.state.token = null
      localStorage.removeItem('jwt')
      localStorage.removeItem('language')
      store.state.isAuthenticated = false
      store.state.userDetails.name = null
      store.state.userDetails.email = null
      store.state.userDetails.companyName = null
      router.push({ path: '/' })
    }
    const getNotfications = () => {
      apiService.getClientNotifications().then((response) => {
        if (response.status === 200) {
          data.totalNewNotifications = response.data.data.new_notif.length
          data.allNewNotifications = response.data.data.new_notif
          data.allOldNotifications = response.data.data.old_notif

          data.newNotifications = data.allNewNotifications.slice(0, 5)
          data.allNewNotifications.splice(0, 5)

          if (data.newNotifications.length < 5) {
            data.oldNotifications = data.allOldNotifications.slice(0, 5 - data.newNotifications.length)
            data.allOldNotifications.splice(0, 5)
          }
        }
      })
        .catch(e => {
          data.errors.push(e)
        })
    }
    const loadMoreNotification = () => {
      if (data.allNewNotifications.length > 0) {
        if (data.allNewNotifications.length < 5) {
          data.newNotifications = data.newNotifications.concat(data.allNewNotifications.slice(0, data.allNewNotifications.length))
          data.allNewNotifications.splice(0, data.allNewNotifications.length)
        } else {
          Array.prototype.push.apply(data.newNotifications, data.allNewNotifications.slice(0, 5));
          data.allNewNotifications.splice(0, 5)
        }
      } else {
        if (data.allOldNotifications.length < 5) {
          data.oldNotifications = data.oldNotifications.concat(data.allOldNotifications.slice(0, data.allOldNotifications.length))
          data.allOldNotifications.splice(0, data.allOldNotifications.length)
        } else {
          Array.prototype.push.apply(data.oldNotifications, data.allOldNotifications.slice(0, 5))
          data.allOldNotifications.splice(0, 5)
        }
      }
    }
    const readtNotfication = (id, index) => {
      apiService.readClientNotifications(id).then((response) => {
        if (response.status === 200) {
          data.oldNotifications.push(data.newNotifications[index])
          data.newNotifications.splice(index, 1)
          data.totalNewNotifications -= 1
        }
      })
        .catch(e => {
          data.errors.push(e)
        })
    }
    const getUserFormInfo = () => {
      apiService.getUserFormInfo().then((response) => {
        if (response.status === 200) {
          data.countries = response.data.data.countries
          data.languages = response.data.data.languages
          data.timeZones = response.data.data.timezones
          data.userOption = response.data.data.user_option
          const index = data.languages.findIndex(obj => obj.id == data.userOption.language_id);
          data.country = data.userOption.country
          data.language = data.languages[index]
          data.timeZone = data.userOption.timezone
          data.row = data.userOption.default_rows
          data.formLoading = false
        }
      })
        .catch(e => {
          data.errors.push(e)
        })
    }
    const saveFormDetails = () => {
      const formJson = {}
      formJson.country = data.country
      formJson.language_id = data.language.id
      formJson.language_iso = data.language.iso2
      formJson.row = data.row
      formJson.timezone = data.timeZone
      apiService.saveUserFormInfo(formJson).then((response) => {
        if (response.status === 200) {
          data.formLoading = false
        }
      })
        .catch(e => {
          data.errors.push(e)
        })
    }
    const getDiffence = (createdAt) => {
      let date2 = Date.parse(createdAt) / 1000;
      let currentDate = new Date()
      let currentUnixTimestamp = Math.floor(currentDate.getTime() / 1000);
      const timeDifferenceMs = currentUnixTimestamp - date2;
      // Convert the time difference from milliseconds to minutes
      const timeDifferenceMinutes = timeDifferenceMs / (60);
      let mins = Math.ceil(timeDifferenceMinutes)
      if (mins > 60) {
        let hours = Math.floor(mins / 60); // Get the whole number of hours
        let remainingMinutes = mins % 60; // Get the remaining minutes

        return hours + " hours and " + remainingMinutes + " minutes";
      }
      return mins + ' Mins';
    }
    onMounted(() => {
      getNotfications()
    })
    const searchBarClass = computed(() => {
      return theme.global.name.value === 'light' ? 'search-bar-light' : 'search-bar-dark';
    });

    return {
      t,
      toggleLanguageDropdown,
      closeDropdownOnClickOutside,
      updateLang,
      getDiffence,
      readtNotfication,
      loadMoreNotification,
      logout,
      openUserForm,
      theme,
      saveFormDetails,
      searchBarClass,
      ...toRefs(data)
    }
  }
}
</script>

<style scoped>
.custom-toolbar {
  overflow: visible;
}

.search-bar-light .form-control {
  background-color: #E0E0E0;
}

.search-bar-dark .form-control {
  background-color: #757575;
}

.user-image {
  border-radius: 50%;
  /* Makes the image circular */
  width: 20px;
  /* Ensures the width is also set to match the height */
  height: 20px;
  /* Height as specified */
  object-fit: cover;
  /* Ensures the image covers the entire circle without distortion */
}
</style>