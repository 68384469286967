import { createStore } from 'vuex'
export default createStore({
  state: {
    isAuthenticated: false,
    token: null,
    mapUpdateCount: 0,
    fileChangeCount: 0,
    user: null,
    showTimerDialog: false,
    localLanguage: 'en',
    navigationFlag: true,
    appBarFlag: true,
    clientDir: null,
    newNotifications: [],
    roles: [],
    isSuperAdmin: false,
    country: null,
    languageIso: null,
    clientId: null,
    timezone: null,
    unAuthorized: true,
    isClient: false,
    search: null,
    clientDialog: false,
    rows: 30,
    dateFormat: 'DD.MM.YYYY HH:MM',
    loading: true,
    userImageUrl: null,
    addSupplierFlag: false,
    themeType: null,
    userDetails: {
      name: null,
      email: null,
      companyName: null
    },
    inactivityTimer: null,
    ffn: true,
    exporto: false,
    parentIds: [],
  },
  getters: {
    themeType(state) {
      return state.themeType
    },
    mapUpdateCount(state) {
      return state.mapUpdateCount
    },
    fileChangeCount(state) {
      return state.fileChangeCount
    },
    search(state) {
      return state.search
    }
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    clearUser(state) {
      state.user = null;
    },
    setInactivityTimer(state, timer) {
      state.inactivityTimer = timer;
    },
    clearInactivityTimer(state) {
      clearTimeout(state.inactivityTimer);
      state.inactivityTimer = null;
    },
    setParentIds(state, parentIds) {
      state.parentIds = parentIds;
    },
    setNavigationFlag(state, value) {
      state.navigationFlag = value;
    },
    setAppBarFlag(state, value) {
      state.appBarFlag = value;
    }
  },
  actions: {
    login({ commit }, user) {
      commit('setUser', user);
      // Start the inactivity timer when the user logs in
      commit('setInactivityTimer', setTimeout(() => {
        commit('clearUser');
        // Perform logout actions here
      }, 60000)); // 60 seconds of inactivity
    },
    logout({ commit }) {
      commit('clearUser');
      commit('clearInactivityTimer');
    },
  },
  modules: {
  }
})
