// import store from "@/store";
// const isSuperAdmin = store.state.isSuperAdmin;

// const menuItems = [
//     {
//         name: "Dashboard",
//         to: "Dashboard",
//         icon: "mdi mdi-view-dashboard",
//     },
//     {
//         name: "Account",
//         to: "userOption",
//         icon: "mdi mdi-account-box",
//     },
//     store.state.isSuperAdmin ? {
//         name: "Change Client",
//         to: "#",
//         icon: "mdi mdi-account-switch",
//         onclick: () => {
//             store.state.clientDialog = true;
//         },
//     } : null,
//     {
//         name: "Manage",
//         heading: true
//     },
//     {
//         name: "PIM",
//         icon: "mdi mdi-database",
//         sub: [
//             {
//                 name: "articles",
//                 to: 'ArticleOverview',
//                 icon: "mdi mdi-newspaper"
//             },
//             {
//                 name: "articleTemplate",
//                 to: 'ArticleDescriptionTemplate',
//                 icon: "mdi mdi-file-document"
//             },
//             {
//                 name: "categories",
//                 to: 'CategoriesOverview',
//                 icon: "mdi mdi-shape"
//             },
//             {
//                 name: "Articleprojects",
//                 to: 'ArticleProject',
//                 icon: "mdi mdi-folder-multiple"
//             },
//             {
//                 name: "Articlefeatures",
//                 to: 'ArticlefeaturesOverview',
//                 icon: "mdi mdi-star-circle"
//             },
//             {
//                 name: "producer",
//                 to: 'ProducersOverview',
//                 icon: "mdi mdi-factory"
//             },
//         ],
//     },
//     {
//         name: "ORM",
//         icon: "mdi mdi-basket-outline",
//         sub: [
//             {
//                 name: "orders",
//                 to: 'OrdersOverview',
//                 icon: "mdi mdi-receipt"
//             },
//             {
//                 name: "Contacts",
//                 to: 'Contacts',
//                 icon: "mdi mdi-account-group"
//             },
//         ],
//     },
//     {
//         name: "Purchase",
//         icon: "mdi mdi-cart-arrow-down",
//         sub: [
//             {
//                 name: "Supplierorders",
//                 to: 'SupplierordersOverview',
//                 icon: "mdi mdi-truck-delivery"
//             },
//             {
//                 name: "Suppliers",
//                 to: 'SupplierOverview',
//                 icon: "mdi mdi-domain"
//             },
//         ],
//     },
//     {
//         name: "shipping",
//         icon: "mdi mdi-truck-fast",
//         sub: [
//             {
//                 name: "deliverynotes",
//                 to: 'DerliveryNotesOverview',
//                 icon: "mdi mdi-note-multiple-outline"
//             },
//             {
//                 name: "Returns",
//                 to: 'Returns',
//                 icon: "mdi mdi-arrow-all"
//             },
//             {
//                 name: "Shippingarts",
//                 to: 'ShippingArtsOverView',
//                 icon: "mdi mdi-package-variant"
//             },
//         ],
//     },
//     {
//         name: "TrackandTrace",
//         icon: "mdi mdi-shipping-pallet",
//         sub: [
//             {
//                 name: "shipments",
//                 to: 'TT',
//                 icon: "mdi mdi-truck-cargo-container"
//             },
//         ],
//     },
//     {
//         name: "reporting",
//         icon: "mdi mdi-chart-line",
//         sub: [
//             {
//                 name: "reports",
//                 to: 'Reports',
//                 icon: "mdi mdi-file-chart"
//             },
//         ],
//     },
//     {
//         name: "Warehouse",
//         icon: "mdi mdi-warehouse",
//         sub: [
//             {
//                 name: "Warehouse",
//                 to: 'Warehouse',
//                 icon: "mdi mdi-warehouse"
//             },
//         ],
//     },
//     {
//         name: "workspace",
//         icon: "mdi mdi-desk",
//         sub: [
//             {
//                 name: "jobber",
//                 to: 'JobberOverview',
//                 icon: "mdi mdi-briefcase"
//             },
//         ],
//     },
//     {
//         name: "settings",
//         icon: "mdi mdi-cog",
//         sub: [
//             {
//                 name: "MyInterfaces",
//                 to: 'MyInterfaces',
//                 icon: "mdi mdi-monitor-dashboard"
//             },
//             {
//                 name: "Dropy Modules",
//                 to: 'NotFound', //no route
//                 icon: "mdi mdi-view-module"
//             },
//             {
//                 name: "users",
//                 to: 'Users',
//                 icon: "mdi mdi-account"
//             },
//             {
//                 name: "logout",
//                 to: 'UserLogout',
//                 icon: "mdi mdi-logout",
//             },
//         ],
//     },
//     store.state.isSuperAdmin ? {
//         name: "superAdmin",
//         icon: "mdi mdi-shield-account",
//         sub: [
//             {
//                 name: "Health Check",
//                 to: 'HealthCheck',
//                 icon: "mdi mdi-heart-pulse"
//             },
//             {
//                 name: "Service Logs",
//                 to: 'PhpInfo',
//                 icon: "mdi mdi-file-search"
//             },
//             {
//                 name: "Public Images",
//                 to: 'PublicImage',
//                 icon: "mdi mdi-image-outline"
//             },
//             {
//                 name: "Register Client",
//                 to: 'RegisterClient',
//                 icon: "mdi mdi-account-plus"
//             },
//         ],
//     } : null,
// ].filter(Boolean); // Filter out null values

// export default {
//     main: menuItems,
// };


export const generateMenuItems = (storeState) => {
    const isSuperAdmin = storeState.isSuperAdmin;

    const menuItems = [
        {
            name: "Dashboard",
            to: "Dashboard",
            icon: "mdi mdi-view-dashboard",
        },
        // {
        //     name: "Inbox",
        //     to: "Inbox",
        //     icon: "mdi mdi-email",
        // },
        {
            name: "Account",
            to: "userOption",
            icon: "mdi mdi-account-box",
        },
        isSuperAdmin ? {
            name: "Change Client",
            to: "#",
            icon: "mdi mdi-account-switch",
            onclick: () => {
                storeState.clientDialog = true;
            },
        } : null,
        {
            name: "Manage",
            heading: true
        },
        {
            name: "PIM",
            icon: "mdi mdi-database",
            sub: [
                {
                    name: "articles",
                    to: 'ArticleOverview',
                    icon: "mdi mdi-newspaper"
                },
                {
                    name: "articleTemplate",
                    to: 'ArticleDescriptionTemplate',
                    icon: "mdi mdi-file-document"
                },
                {
                    name: "categories",
                    to: 'CategoriesOverview',
                    icon: "mdi mdi-shape"
                },
                {
                    name: "Articleprojects",
                    to: 'ArticleProject',
                    icon: "mdi mdi-folder-multiple"
                },
                {
                    name: "Articlefeatures",
                    to: 'ArticlefeaturesOverview',
                    icon: "mdi mdi-star-circle"
                },
                {
                    name: "producer",
                    to: 'ProducersOverview',
                    icon: "mdi mdi-factory"
                },
            ],
        },
        {
            name: "ORM",
            icon: "mdi mdi-basket-outline",
            sub: [
                {
                    name: "orders",
                    to: 'OrdersOverview',
                    icon: "mdi mdi-receipt"
                },
                {
                    name: "Contacts",
                    to: 'Contacts',
                    icon: "mdi mdi-account-group"
                },
            ],
        },
        {
            name: "Purchase",
            icon: "mdi mdi-cart-arrow-down",
            sub: [
                {
                    name: "Supplierorders",
                    to: 'SupplierordersOverview',
                    icon: "mdi mdi-truck-delivery"
                },
                {
                    name: "Suppliers",
                    to: 'SupplierOverview',
                    icon: "mdi mdi-domain"
                },
            ],
        },
        {
            name: "shipping",
            icon: "mdi mdi-truck-fast",
            sub: [
                {
                    name: "deliverynotes",
                    to: 'DerliveryNotesOverview',
                    icon: "mdi mdi-note-multiple-outline"
                },
                {
                    name: "shipping",
                    to: "ShippingOverview",
                    icon: "fa fa-dolly"
                },
                {
                    name: "Returns",
                    to: 'Returns',
                    icon: "mdi mdi-arrow-all"
                },
                {
                    name: "Shippingarts",
                    to: 'ShippingArtsOverView',
                    icon: "mdi mdi-package-variant"
                },
            ],
        },
        {
            name: "TrackandTrace",
            icon: "mdi mdi-shipping-pallet",
            sub: [
                {
                    name: "shipments",
                    to: 'TT',
                    icon: "mdi mdi-truck-cargo-container"
                },
            ],
        },
        {
            name: "reporting",
            icon: "mdi mdi-chart-line",
            sub: [
                {
                    name: "reports",
                    to: 'Reports',
                    icon: "mdi mdi-file-chart"
                },
            ],
        },
        {
            name: "Warehouse",
            icon: "mdi mdi-warehouse",
            sub: [
                {
                    name: "Warehouse",
                    to: 'Warehouse',
                    icon: "mdi mdi-warehouse"
                },
                {
                    name: "StockTransfer",
                    to: 'StockTransfer',
                    icon: "mdi mdi-truck"
                },
            ],
        },
        {
            name: "workspace",
            icon: "mdi mdi-desk",
            sub: [
                {
                    name: "jobber",
                    to: 'JobberOverview',
                    icon: "mdi mdi-briefcase"
                },
            ],
        },
        // {
        //     name: "Customer Care",
        //     icon: "mdi mdi-headset",
        //     sub: [
        //         {
        //             name: "settings",
        //             icon: "mdi mdi-cog",
        //             sub: [
        //                 {
        //                     name: "Accounts",
        //                     to: "CS",
        //                     icon: "mdi mdi-account-group"
        //                 },

        //             ]
        //         }
        //     ]

        // },
        {
            name: "settings",
            icon: "mdi mdi-cog",
            sub: [
                {
                    name: "MyInterfaces",
                    to: 'MyInterfaces',
                    icon: "mdi mdi-monitor-dashboard"
                },
                {
                    name: "Dropy Modules",
                    to: 'NotFound', //no route
                    icon: "mdi mdi-view-module"
                },
                {
                    name: "users",
                    to: 'Users',
                    icon: "mdi mdi-account"
                },
                {
                    name: "logout",
                    to: 'UserLogout',
                    icon: "mdi mdi-logout",
                },
            ],
        },
        isSuperAdmin ? {
            name: "superAdmin",
            icon: "mdi mdi-shield-account",
            sub: [
                {
                    name: "Contracts",
                    to: 'Contract',
                    icon: "mdi mdi-clipboard-text"
                },
                {
                    name: "orders",
                    icon: "mdi mdi-cart",
                    sub: [
                        {
                            name: "Add Missing Orders",
                            to: "MissingOrder",
                            icon: "mdi mdi-cart-plus"
                        }
                    ]
                },
                // {
                //     name:"Shippers",
                //     to:"Shipper",
                //     icon:"mdi mdi-ferry"
                // },
                {
                    name: "Health Check",
                    to: 'HealthCheck',
                    icon: "mdi mdi-heart-pulse"
                },
                {
                    name: "Service Logs",
                    to: 'PhpInfo',
                    icon: "mdi mdi-file-search"
                },
                {
                    name: "Public Images",
                    to: 'PublicImage',
                    icon: "mdi mdi-image-outline"
                },
                {
                    name: "Register Client",
                    to: 'RegisterClient',
                    icon: "mdi mdi-account-plus"
                },
            ],
        } : null,
    ].filter(Boolean);

    return menuItems;
};
